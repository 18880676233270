<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="12">
          <br /><br />
          <div class="closeBtn mb-2 text-right">
            <p @click="closeCurrentTab()" class="btn m-0">
              <i class="fas fa-window-close fa-4x text-danger"></i>
            </p>
          </div>
          <h6 class="alert alert-danger" v-if="CsvError">
            Invali format/Missing Data, Please check the excel sheet
          </h6>
          <h6 class="alert alert-success text-center" v-if="responseSuccess.created">
            {{ responseSuccess.created[0] }}
          </h6>
          <div class="selectExcelFile d-flex align-items-center">
            <b-form-file ref="selectedExcelFile" style="display: none" v-model="excelSheet"
              placeholder="Select Excel File"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></b-form-file>
            <b-button variant="info" v-if="excelSheet == null" pill size="md" @click="selectExcelFile()"><i
                class="fas fa-file-excel mr-2"></i>Select Excel File</b-button>

            <h6 v-else class="text-dark font-weight-bolder">
              {{ excelSheet.name }}
              <span v-if="!outcomeBtn.disabled" class="text-danger btn m-0 p-0" @click="removeExcelSheet()"><i
                  class="fas fa-trash ml-2"></i></span>
            </h6>
            <b-button v-if="excelTable.length == 0" variant="outline-success" @click="checkExcelSheet"
              class="text-info ml-3" :disabled="excelSheet == null"><i class="fas fa-upload fa-2x ml-2"></i></b-button>
          </div>
          <div class="progressBar" v-if="loadData">
            <b-progress :value="value" :max="max" show-progress animated variant="success"></b-progress>
          </div>
          <div class="bg-white p-3" v-if="excelTable.length != 0">
            <b-form @submit.prevent="createCourseModule()">
              <table class="table table-bordered table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>Si.No</th>
                    <th>Course Module</th>
                    <th>Course Description</th>
                    <th>Contact Hours</th>
                    <th>Sem Exam Marks %</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(cOutcome, cOutcomeIndex) in excelTable" :key="cOutcomeIndex">
                    <td>
                      <b-form-input v-model="cOutcome.si_no" placeholder="Serial number"
                        :disabled="outcomeBtn.disabled"></b-form-input>
                    </td>
                    <td class="align-middle">
                      <b-form-input v-model="cOutcome.name" placeholder="Course Module" :disabled="outcomeBtn.disabled"
                        required :class="{ 'is-invalid': responseErrors.module }"></b-form-input>
                      <span class="text-danger" v-if="responseErrors.module">{{
                          responseErrors.module[0]
                      }}</span>
                    </td>
                    <td class="align-middle">
                      <b-form-textarea :class="{ 'is-invalid': responseErrors.lessons }" :disabled="outcomeBtn.disabled"
                        v-model="cOutcome.description" placeholder="Enter Description..." required rows="3"
                        max-rows="6"></b-form-textarea>
                      <span class="text-danger" v-if="responseErrors.lessons">{{
                          responseErrors.lessons[0]
                      }}</span>
                    </td>

                    <td class="align-middle">
                      <b-form-input v-model="cOutcome.contact_hours" placeholder="Contact Hours"
                        :disabled="outcomeBtn.disabled" required></b-form-input>
                    </td>
                    <td class="align-middle">
                      <b-form-input v-model="cOutcome.totel_score_for_exam" placeholder="Exam Marks %" required>
                      </b-form-input>
                    </td>
                    <td class="align-middle">
                      <span v-if="!outcomeBtn.disabled" class="text-danger btn m-0 p-0" :disabled="outcomeBtn.disabled"
                        @click="excelTable.splice(cOutcomeIndex, 1)"><i class="fas fa-trash"></i></span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-form-group>
                <b-button v-html="outcomeBtn.text" type="submit" variant="primary" size="md"
                  :disabled="outcomeBtn.disabled" class="d-flex align-items-center">{{ outcomeBtn.text }}</b-button>
              </b-form-group>
            </b-form>
          </div>
          <div class="sampleCsv" v-if="excelTable.length == 0">
            <p class="text-info d-flex align-items-baseline">
              <b class="text-dark mr-2">Note: </b> While preparing the Excel sheet, only include the serial number,
              course outcome name, and course outcome description. Headers are not required.
              <br />
              Please refer to the sample image below:
            </p>
            <img src="../../../../../assets/images/courseModule.png" alt="" class="img-fluid" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import readXlsxFile from "read-excel-file";
export default {
  data() {
    return {
      loadData: false,
      value: 50,
      max: 100,
      excelSheet: null,
      excelTable: [],
      checkCsv: [],
      CsvError: false,
      outcomeBtn: {
        text: "Submit",
        disabled: false,
      },
      responseErrors: {},
      responseSuccess: {},
    };
  },
  methods: {
    async createCourseModule() {
      const courseId = this.$route.params.course_id;
      this.outcomeBtn.text =
        `This process will take a few seconds, please wait` +
        `<div class="spinner-grow text-white ml-3"></div>`;
      this.outcomeBtn.disabled = true;

      for (let cModule in this.excelTable) {
        let newCoursemodule = await this.storeCourseModule(cModule, courseId);
        newCoursemodule;
      }
    },
    async storeCourseModule(cModule, courseId) {
      const url = this.$store.getters.getAPIKey.courseModules;
      const courseModule = {
        si_no: this.excelTable[cModule].si_no,
        module: this.excelTable[cModule].name,
        lessons: this.excelTable[cModule].description,
        contact_hours: this.excelTable[cModule].contact_hours.toString(),
        totel_score_for_exam: this.excelTable[cModule].totel_score_for_exam.toString(),
        course_id: courseId,
      };
      await axios
        .post(url, courseModule)
        .then(() => {
          this.$toast.info(
            `${this.excelTable[cModule].name} is created successfuly`,
            {
              position: "top-right",
              duration: 3000,
            }
          );

          if (cModule == this.excelTable.length - 1) {
            this.responseSuccess["created"] = "Modules Created";
            this.$toast.success("Course modules are created successfully.", {
              position: "top",
              duration: 0,
            });
            this.clearResponse();
          }

          // console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            this.responseErrors = error.response.data.errors;
            this.clearResponse();
          }

          // console.log(error.response);
          this.$toast.warning(
            Object.keys(this.responseErrors)[0] + " " + "is required",
            {
              position: "top",
              duration: 3000,
            }
          );
        });
    },
    clearResponse() {
      this.$forceUpdate();
      this.outcomeBtn.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess.created) {
          this.removeExcelSheet();
          this.$toast.clear();
          this.closeCurrentTab();
        }

        this.outcomeBtn.disabled = false;
        this.responseErrors = {};
        this.responseSuccess = {};
      }, 5000);
    },
    selectExcelFile() {
      this.$refs.selectedExcelFile.$refs.input.click();
    },
    removeExcelSheet() {
      this.excelTable = [];
      this.excelSheet = null;
    },
    async checkExcelSheet() {
      this.loadData = true;
      this.checkCsv = [];
      await this.excelSheet;

      this.excelTable = [];

      await readXlsxFile(this.excelSheet).then((csv) => {
        this.checkCsv = csv;
        this.value = this.value + 10;
      });

      let checkValidation;
      await this.checkCsv.forEach((csv) => {
        if (csv.length != 5) {
          return (checkValidation = true);
        }
        // if (this.checkCsv[this.checkCsv.length - 1].length <= 4) {
        //   return (checkValidation = false);
        // }
      });

      if (checkValidation) {
        this.value = this.value + 10;
        this.CsvError = true;
        this.loadData = false;
        this.removeExcelSheet();
        setTimeout(() => {
          this.CsvError = false;
        }, 5000);
      } else {
        this.value = this.value + 20;
        await this.checkCsv.forEach((cOData) => {
          this.excelTable.push({
            si_no: cOData[0],
            name: cOData[1],
            description: cOData[2],
            contact_hours: cOData[3],
            totel_score_for_exam: cOData[4],
          });
        });
        this.loadData = false;
      }
    },
  },
};
</script>
